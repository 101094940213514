import React, {useContext, useState} from 'react';
import {SpasClientContext} from "@components/client/contexts/SpasClientContext";
import ClientPortfolioSelector
    from "@components/client/ClientPortfolioSelector";
import {theme} from "antd";
import {
    ReportSettingsContainer
} from "@holistico/proposal/view/report/ReportSettingsModal";


const {useToken} = theme;


const ClientReport = () => {
    const {client} = useContext(SpasClientContext);
    const {token} = useToken();

    const [selectedPortfolio, setSelectedPortfolio] = useState({
        name: 'Unknown', calculated_values: {}
    })
    const [selectedBenchmark, setSelectedBenchmark] = useState({
        name: 'Unknown', calculated_values: {}
    })

    return <>
        <ClientPortfolioSelector
            setActual={setSelectedPortfolio}
            setBenchmark={setSelectedBenchmark}
        />
        <div style={{
            marginTop: token.marginLG,
            padding: token.paddingLG,
            background: token.colorBgGreyDark,
            border: `1px solid ${token.colorSplit}`,
            borderRadius: token.borderRadiusLG,
            overflow: "hidden",
        }}>
            <ReportSettingsContainer
                proposal={client}
                reportClass={"client"}
                idsMain={selectedPortfolio?._id?.split(',')}
                idsProposed={selectedBenchmark?._id?.split(',')}
                cvMain={selectedPortfolio.calculated_values}
                cvProposed={selectedBenchmark.calculated_values}
                collapseSections={true}
            />
        </div>
    </>
}

export default ClientReport;
