import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Checkbox,
    Collapse,
    ConfigProvider,
    Flex,
    message,
    Modal,
    Spin, Steps,
    theme, Timeline,
    Tooltip, Typography,
} from "antd";
import {
    createReport,
    updateReportPreset,
    deleteReportPreset,
    getReportBasics,
} from "@API/report";
import {downloadFile} from "@global/FileDownloader";
import MultiColumnDnD from "@holistico/proposal/view/report/MultiColumnDnD";
import sortSections
    from "@holistico/proposal/view/report/SectionSorter";
import CreateTemplateModal
    from "@holistico/proposal/view/report/CreateTemplateModal";
import NestedCard from "@components/retail/cards/NestedCard";
import CardHeader from "@components/retail/cards/CardHeader";
import HRadioGroup from "@holistico/aux/HRadioGroup";
import {useSize} from "ahooks";
import {
    CheckOutlined,
    CloseOutlined,
    DownOutlined,
    UpOutlined
} from "@ant-design/icons";


const {useToken} = theme;


const getDefaultPreset = (sections, reportClass = "client", ) => {
    const keys = sections.map(section => section.key);

    return {
        "key": "default_preset_43ec391b",
        "title": "Default",
        "description": "Default report configuration",
        "type": "global",
        "report_class": reportClass,
        "version": "2.0",
        "sectionsOrder": {
            "all": keys,
            "current": keys,
            "proposed": keys,
            "both": keys
        },
        "selectedSections": {
            "all": [],
            "current": [],
            "proposed": [],
            "both": keys
        },
        "sectionsFilter": "both",
        "options": {
            "show_volatility": true
        },
    }
}


const ReportPresetList = ({presets, selected, onSelect}) => {
    const {token} = useToken();

    const options = presets.map(preset => {
        return {
            value: preset.key,
            label: <Tooltip title={preset.description}>
                {preset.title}
            </Tooltip>
        }
    })

    return <HRadioGroup
        options={options}
        value={selected?.key}
        onChange={onSelect}
        size={"large"}
    />
}


const SectionFilterList = ({selectedPreset, setSelectedPreset}) => {
    const {token} = useToken();

    return <HRadioGroup
        options={[
            {
                label: "Current vs proposed",
                value: "both"
            },
            {
                label: "Current portfolio",
                value: "current"
            },
            {
                label: "Proposed portfolio",
                value: "proposed"
            },
            {
                label: "Custom",
                value: "all"
            },
        ]}
        value={selectedPreset?.sectionsFilter}
        onChange={(value) => {
            setSelectedPreset(prev => {
                prev.sectionsFilter = value;
                return {...prev};
            })
        }}
        style={{marginBottom: token.marginLG}}
    />
}


const SelectAllButtons = ({setSelectedPreset}) => {
    const {token} = useToken();

    return <Flex
        gap={"large"}
        style={{
            marginBottom: token.marginXS,
        }}
    >
        <Button
            color={"default"}
            variant={"link"}
            size={"small"}
            style={{
                fontSize: token.fontSizeSM,
                textDecoration: "underline",
                padding: 0
            }}
            onClick={() => {
                setSelectedPreset(prev => {
                    prev.selectedSections[prev.sectionsFilter] = prev.sectionsOrder[prev.sectionsFilter];
                    return {...prev};
                })
            }}
        >
            <CheckOutlined/>
            Select all
        </Button>
        <Button
            color={"default"}
            variant={"link"}
            size={"small"}
            style={{
                fontSize: token.fontSizeSM,
                textDecoration: "underline",
                padding: 0
            }}
            onClick={() => {
                setSelectedPreset(prev => {
                    prev.selectedSections[prev.sectionsFilter] = [];
                    return {...prev};
                })
            }}
        >
            <CloseOutlined/>
            Deselect all
        </Button>
    </Flex>
}


const SectionsPanel = ({
                           selectedPreset,
                           setSelectedPreset,
                           availableSections,
                           collapse = false
                       }) => {
    const [collapsed, setCollapsed] = useState(collapse);
    const {token} = useToken();

    const filter = selectedPreset?.sectionsFilter;
    const order = selectedPreset?.sectionsOrder[filter];
    const selected = selectedPreset?.selectedSections[filter];
    const nSelected = order?.filter(key => selected.includes(key)).length ?? 0;

    return <Flex vertical align={"flex-start"} style={{
        marginTop: collapsed ? -token.marginXS : 0,
    }}>
        {collapsed
            && <Typography.Text type={nSelected ? "default": "danger"}>
                {nSelected} sections selected
            </Typography.Text>
        }
        {!collapsed
            && <SelectAllButtons setSelectedPreset={setSelectedPreset}/>
        }
        {!collapsed
            && <MultiColumnDnD
                sections={selectedPreset?.sectionsOrder[selectedPreset.sectionsFilter].map(key => availableSections.find(section => section.key === key))}
                selected={selectedPreset?.selectedSections[selectedPreset.sectionsFilter]}
                setSelected={(selected) => {
                    setSelectedPreset(prev => {
                        prev.selectedSections[prev.sectionsFilter] = selected;
                        return {...prev};
                    })
                }}
                setOrder={(order) => {
                    setSelectedPreset(prev => {
                        prev.sectionsOrder[prev.sectionsFilter] = order;
                        return {...prev};
                    })
                }}
                resetTriggers={[selectedPreset?.key, selectedPreset?.sectionsFilter]}
            />
        }
        <Button
            type={"link"}
            size={"small"}
            onClick={() => setCollapsed(prev => !prev)}
            style={{
                padding: 0,
                fontSize: token.fontSizeSM,
                marginTop: token.marginXS,
            }}
        >
            {collapsed ? <DownOutlined/> : <UpOutlined/>}
            {collapsed ? "Show sections" : "Hide sections"}
        </Button>
    </Flex>
}


const OptionsPanel = ({
                          selectedPreset,
                          setSelectedPreset
                      }) => {
    const {token} = useToken();

    return <Flex style={{
        marginBottom: token.margin
    }}>
        <Checkbox
            checked={selectedPreset?.options?.show_volatility}
            onChange={(e) => {
                setSelectedPreset(prev => {
                    prev.options.show_volatility = e.target.checked;
                    return {...prev};
                })
            }}
        >
            Show projected return and volatility
        </Checkbox>
    </Flex>
}


const ButtonsPanel = ({
                          selectedPreset,
                          createReportOnClick,
                          loadPresets,
                          setLoadingStatus,
                          setOpenCreateModal,
                      }) => {
    const {token} = useToken();
    const ref = useRef(null);
    const size = useSize(ref);

    const smallScreen = size?.width < 450;

    return <Flex
        gap={"small"}
        justify={"flex-start"}
        align={"center"}
        wrap={"wrap"}
        ref={ref}
        style={{
            paddingTop: token.paddingSM,
            paddingBottom: token.paddingSM,
        }}
    >
        <Button
            type="primary"
            // size={"large"}
            style={{width: smallScreen ? "100%" : "auto"}}
            onClick={createReportOnClick}
        >
            Generate report
        </Button>
        {selectedPreset?.type === 'user' &&
            <Button
                type={"link"}
                // size={"large"}
                style={{width: smallScreen ? "100%" : "auto"}}
                onClick={() => {
                    setLoadingStatus("saving template");
                    updateReportPreset(selectedPreset, (data, error) => {
                        if (error) {
                            message.success("Error while saving template!");
                        } else {
                            message.success("Template was saved successfully!");
                        }
                        setLoadingStatus("done");
                    })
                }}
            >
                Save template
            </Button>
        }
        <Button
            type={"link"}
            // size={"large"}
            style={{width: smallScreen ? "100%" : "auto"}}
            onClick={() => {
                setOpenCreateModal(true)
            }}
        >
            Save as new
        </Button>
        {selectedPreset?.type === 'user' &&
            <Button
                type={"link"}
                // size={"large"}
                style={{width: smallScreen ? "100%" : "auto"}}
                onClick={() => {
                    setLoadingStatus("deleting template")
                    deleteReportPreset(selectedPreset?._id, () => {
                        message.success("Template was deleted successfully!")
                        loadPresets();
                    })
                }}
            >
                Delete template
            </Button>
        }
    </Flex>
}


export const ReportSettingsContainer = ({
                                            proposal,
                                            reportClass,
                                            idsMain,
                                            idsProposed,
                                            cvMain = null,
                                            cvProposed = null,
                                            defaultTemplateKey = null,
                                            onCancel = null,
                                            notesInReport = true,
                                            collapseSections = false,
                                        }) => {
    const [presets, setPresets] = useState([]);
    const [availableSections, setAvailableSections] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState("loading templates");
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const {token} = useToken();

    useEffect(() => {
        loadPresets(defaultTemplateKey);
    }, [])

    const loadPresets = (defaultSelectedKey = null) => {
        getReportBasics(reportClass === "enrollment" ? "client" : reportClass, data => {
            setLoadingStatus("done");
            setAvailableSections(data.available_sections);
            if(!data.presets || data.presets.length === 0) {
                data.presets = [
                    getDefaultPreset(data.available_sections, reportClass)
                ]
            }
            setPresets(data.presets);
            choosePreset(defaultSelectedKey, data);
        });
    }

    const choosePreset = (value, data = null) => {
        const _presets = data ? data.presets : presets;
        const _availableSections = data ? data.available_sections : availableSections;
        const preset = structuredClone(
            _presets.filter(p => p.key === value)[0]
            ?? _presets.filter(p => p.key === defaultTemplateKey)[0]
            ?? _presets[0]
        );
        // Insert new sections to old presets
        for (const filter in preset.sectionsOrder) {
            const _allSections = _availableSections
                .filter(section => filter === 'all' | !section.bucket_filter | section.bucket_filter.includes(filter));
            preset.sectionsOrder[filter] = sortSections(_allSections, preset.sectionsOrder[filter])
                .map(section => section.key);
        }
        setSelectedPreset(preset);
    }

    const createReportOnClick = () => {
        setLoadingStatus("generating report");

        const filter = selectedPreset.sectionsFilter;
        const order = selectedPreset.sectionsOrder[filter];
        let selected = selectedPreset.selectedSections[filter];
        if (["enrollment", "client"].includes(reportClass) && !notesInReport) {
            selected = selected.filter(section => section !== "notes")
        }

        createReport({
                main_ids: idsMain,
                benchmark_ids: idsProposed,
                main_calculated_values: idsMain && idsMain.length > 1 ? cvMain : null,
                benchmark_calculated_values: idsProposed && idsProposed.length > 1 ? cvProposed : null,
                client_id: proposal ? proposal._id : null,
                sections: order.filter(key => selected.includes(key)),
                show_volatility: selectedPreset.options.show_volatility,
                report_class: reportClass
            }, (response, error) => {
                if (error) {
                    message.error("Error while creating report!");
                    setLoadingStatus("done");
                    return
                }
                let file_type = response.headers['content-type'];
                let file_name;
                if (proposal) {
                    file_name = `${proposal.full_name ?? "Proposal"} (${new Date().toLocaleString()}).pdf`;
                } else {
                    file_name = `Playground report (${new Date().toLocaleString()}).pdf`;
                }
                downloadFile(response.data, file_name, file_type, () => {
                    setLoadingStatus("done");
                })
                message.success("Report was generated successfully!")
                onCancel?.()
            }
        )
    }

    return <>
        <Spin
            style={{minHeight: 300}}
            spinning={loadingStatus !== "done"}
            tip={
                {
                    "generating report": "Generating report file...",
                    "loading templates": "Loading templates...",
                    "deleting template": "Deleting template...",
                    "saving template": "Saving template...",
                }[loadingStatus] || ""
            }
            size={"large"}
        >
            <NestedCard background={token.colorBgGrey}>
                <NestedCard background={"white"}>
                    <CardHeader title="Report template"/>
                    <ReportPresetList
                        presets={presets}
                        selected={selectedPreset}
                        onSelect={(key) => choosePreset(key)}
                    />
                </NestedCard>
                <Steps
                    progressDot
                    direction="vertical"
                    current={4}
                    items={[
                        {
                            title: <Typography.Text strong>
                                Options
                            </Typography.Text>,
                            description: <div style={{
                                marginTop: token.marginXS,
                                marginBottom: token.marginXL
                            }}>
                                <OptionsPanel
                                    selectedPreset={selectedPreset}
                                    setSelectedPreset={setSelectedPreset}
                                />
                            </div>
                        },
                        {
                            title: <Typography.Text strong>
                                Layout
                            </Typography.Text>,
                            description: <div style={{
                                marginTop: token.marginXS,
                                marginBottom: token.marginXL
                            }}>
                                <SectionFilterList
                                    selectedPreset={selectedPreset}
                                    setSelectedPreset={setSelectedPreset}
                                />
                            </div>
                        },
                        {
                            title: <Typography.Text strong>
                                Sections
                            </Typography.Text>,
                            description: <div style={{
                                marginTop: token.marginXS,
                                marginBottom: token.marginXL
                            }}>
                                <SectionsPanel
                                    selectedPreset={selectedPreset}
                                    setSelectedPreset={setSelectedPreset}
                                    availableSections={availableSections}
                                    collapse={collapseSections}
                                />
                            </div>
                        },
                    ]}
                />
            </NestedCard>
            <ButtonsPanel
                selectedPreset={selectedPreset}
                createReportOnClick={createReportOnClick}
                loadPresets={loadPresets}
                setLoadingStatus={setLoadingStatus}
                setOpenCreateModal={setOpenCreateModal}
            />
        </Spin>
        {selectedPreset
            && <CreateTemplateModal
                open={openCreateModal}
                preset={selectedPreset}
                onCancel={() => setOpenCreateModal(false)}
                onSave={(key) => {
                    loadPresets(key);
                    setOpenCreateModal(false)
                }}
            />
        }
    </>
}


const ReportSettingsModal = ({
                                 open,
                                 onCancel,
                                 proposal,
                                 reportClass,
                                 idsMain,
                                 idsProposed,
                                 cvMain = null,
                                 cvProposed = null,
                                 defaultTemplateKey = null,
                                 collapseSections = false,
                                 notesInReport = true
                             }) => {
    const {token} = useToken();

    return <ConfigProvider theme={{
        components: {
            Modal: {contentBg: token.colorBgGreyDark}
        }
    }}>
        <Modal
            open={open}
            closable={true}
            title={null}
            footer={null}
            width={"94%"}
            style={{maxWidth: 1200}}
            onCancel={onCancel}
        >
            <ReportSettingsContainer
                proposal={proposal}
                reportClass={reportClass}
                idsMain={idsMain}
                idsProposed={idsProposed}
                cvMain={cvMain}
                cvProposed={cvProposed}
                defaultTemplateKey={defaultTemplateKey}
                onCancel={onCancel}
                notesInReport={notesInReport}
                collapseSections={collapseSections}
            />
        </Modal>
    </ConfigProvider>
}

export default ReportSettingsModal;
